/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaArrowCircleLeft, FaBuysellads, FaChartBar, FaHeart, FaTag, FaEllipsisV, FaAngleDown } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import {
	Badge,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	ListGroup,
	ListGroupItem,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';

import { useFetchTotalUnreadCount } from '../../api-hooks/conversation';
import { getChannelLink, getLink, Path } from '../../RoutePath';
import { useFetchGraphGlobalsFriendRequest } from '../../api-hooks/graph/globals';
import { useFetchNotificationUnreadCount } from '../../api-hooks/notification/notification';
import { AdRewardEstimator } from '../../components/Ad/RewardEstimator';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { FriendRequestStatus } from '../../components/Channel/Description/FriendRequestStatus';
import { useMenu } from '../../components/Drawer/Context';
import Hexagon from '../../components/Hexagon/Hexagon';
import { useLogin } from '../../components/Login/Provider';
import { usePoints } from '../../components/Membership/Points';
import { useOrganizationSettings } from '../../components/OrganizationSettings/Context';
import { ScreenThemeLayout, useScreenTheme } from '../../components/Studio/ScreenTheme/Provider';
import { UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';
import { PointsBalance } from '../../components/Transactions/Balance/Balance';
import defaultAvatar from '../../images/default-avatar.png';
import { AVATAR_SIZE, getFileUrl } from '../../lib/file';
import { useHandleDeleteDisableSubscription } from '../../lib/serviceWorkerHook';
import {
	AccountIcon,
	DarkThemeToggle,
	FriendsIcon,
	LibraryIcon,
	LightThemeToggle,
	MessagesIcon,
	SignOffIcon,
	SurveysIcon,
} from './icons';
import { PREVIOUS_SESSION_KEY } from '../../lib/Identity';
import { useFetchDefaultOrganization } from '../../api-hooks/public/organization';
import { useFetchOrganizations } from '../../api-hooks/organization/organization';
import { useStudio } from '../../components/Studio/Context';
import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { RolePaymentType } from '../../components/OrganizationSettings/Role/RoleForm';
import { useProfile } from '../../components/Profile/ProfileContext';
import { useOrganizationSwitch } from '../../components/OrganizationSwitch/Context';
import { LibraryModalTab } from '../../components/UserSettings/LibraryModal/LibraryModalTab';
import { AnalyticsAndAdvertisingMenuItems } from '../../components/UserSettings/AnalyticsAndAdvertising/Content';
import { currencyLabel, systemCurrencyLabel } from '../../lib/currency';
import { MyChannelIcon } from '../SidebarMenu/icons';
import './HeaderUserbox.scss';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';
/**
 * @typedef {{
 * 	isFullscreen?: boolean,
 * }} HeaderUserboxProps
 */

const HeaderUserbox = (
/** @type {HeaderUserboxProps} */
	{ isFullscreen = false },
) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { profile } = useProfile();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const { openOrganizationSwitchModal } = useOrganizationSwitch();

	const { data: organizations } = useFetchOrganizations();

	const { data: defaultOrganization } = useFetchDefaultOrganization();

	const { logout, restorePreviousSession } = useAuthentication();

	const { openModal, openAnalyticsAndAdModal } = useUserSettings();
	const { openOrganizationModal } = useOrganizationSettings();
	const { openStudioLeaveConfirmationModal } = useStudio();

	const { isCurrentStudioStarted } = useCurrentStudio();

	const { balance, chipBalance } = usePoints();
	const { handleCloseRoutingModal } = useLogin();
	const { currentScreenTheme, handleChangeCurrentScreenTheme } = useScreenTheme();
	const deleteSubscription = useHandleDeleteDisableSubscription();

	const { toggleMenu, setActiveTab } = useMenu();

	const { data: unreadCounts } = useFetchTotalUnreadCount();

	const { data: unreadNotifications } = useFetchNotificationUnreadCount();

	const totalUnreadCount = useMemo(() => (unreadCounts?.total ?? 0)
		+ (unreadNotifications?.count ?? 0),
	[unreadCounts, unreadNotifications]);

	const { data: friendRequests } = useFetchGraphGlobalsFriendRequest();
	const [pendingFriendRequests, setPendingFriendRequests] = useState([]);

	const showPoints = useMemo(() => {
		if (profile?.organization?._id !== defaultOrganization?._id) {
			return !!profile?.organizationOwner
				|| profile?.organizationRole?.paymentType === RolePaymentType.PAID;
		}

		return true;
	},
	[defaultOrganization, profile]);

	useEffect(() => {
		if (friendRequests) {
			setPendingFriendRequests(
				friendRequests.filter(
					(friendRequest) => friendRequest.receiver._id === profile?._id
						&& friendRequest.status === FriendRequestStatus.PENDING,
				),
			);
		}
	}, [friendRequests, profile]);

	const userAvatar = profile?.avatar
		? getFileUrl({ name: profile.avatar }, AVATAR_SIZE.width, AVATAR_SIZE.height)
		: defaultAvatar;

	const toggleDropdown = useCallback(() => setIsDropdownOpen((prevState) => !prevState), []);

	const isOrgPublicChannel = localStorage.getItem(PREVIOUS_SESSION_KEY);

	const handleSignOff = useCallback(async () => {
		if (isOrgPublicChannel) {
			restorePreviousSession();
		} else {
			logout();
		}

		await deleteSubscription();
		handleCloseRoutingModal();
		toggleDropdown();
		navigate('/');
	}, [
		deleteSubscription,
		handleCloseRoutingModal,
		navigate,
		logout,
		restorePreviousSession,
		toggleDropdown,
		isOrgPublicChannel,
	]);

	const openUserSettings = useCallback((open) => () => {
		toggleDropdown();
		open();
	}, [toggleDropdown]);

	const openOrganizationSettings = useCallback(() => () => {
		toggleDropdown();
		openOrganizationModal();
	}, [openOrganizationModal, toggleDropdown]);

	const handleOpenSwitchModal = useCallback(() => {
		if (isCurrentStudioStarted) {
			openStudioLeaveConfirmationModal({
				onConfirm: openOrganizationSwitchModal,
			});
		} else {
			openOrganizationSwitchModal();
		}
	}, [openStudioLeaveConfirmationModal, openOrganizationSwitchModal, isCurrentStudioStarted]);

	const handleSingOffWithConfirmation = useCallback(() => {
		if (isCurrentStudioStarted) {
			openStudioLeaveConfirmationModal({
				onConfirm: () => handleSignOff(true),
			});
		} else {
			handleSignOff(true);
		}
	}, [openStudioLeaveConfirmationModal, handleSignOff, isCurrentStudioStarted]);

	const showMembership = defaultOrganization?._id === profile?.organization?._id
		|| profile?.organizationRole?.paymentType === 'PAID' || profile?.organizationOwner;

	return (
		<div className="d-flex flex-row align-items-center h-100">
			<div className="position-relative d-flex p-0">
				{totalUnreadCount > 0 || pendingFriendRequests.length ? (
					<Badge
						color="danger"
						className="mt-2 badge-circle position-absolute top-0 z-3"
					>
						<span />
					</Badge>
				) : null}
				<Link
					className="d-flex"
					onClick={(e) => e.stopPropagation()}
					to={getLink(Path.CHANNEL, { hashtag: profile?.hashtag })}
				>
					<Hexagon style={{ height: '50px' }}>
						<img src={userAvatar} className="h-100 w-auto mw-100 img-fit-container" alt={t('HeaderUserbox.Index.avatar')} />
					</Hexagon>
				</Link>
			</div>
			<Dropdown
				className="HeaderUserbox position-relative d-flex"
				isOpen={isDropdownOpen}
				toggle={toggleDropdown}
			>
				<DropdownToggle
					color="lighter"
					className="p-0 text-left d-flex align-items-center shadown-none"
					style={{ height: '50px' }}
				>
					<div
						className="d-flex align-items-center mx-0 mx-lg-2"
						style={{ pointerEvents: 'none' }} // Prevent dropdown issue that was toggling twice
					>
						<div>
							<div className="d-flex align-items-center">
								<strong className={clsx('d-none d-lg-block', { 'text-white': isFullscreen })}>{profile?.nickname}</strong>
								<FaAngleDown
									className="ml-xl-2 text-secondary"
								/>
							</div>
							{showPoints && (
								<span className="text-secondary d-none d-lg-block">
									{balance ? (<PointsBalance balance={balance} name={currencyLabel} />) : `0 ${currencyLabel}`}
									{chipBalance ? (<><span> &amp; </span><PointsBalance balance={chipBalance} name={systemCurrencyLabel} symbolSize={18} /></>) : ` & 0 ${systemCurrencyLabel}`}
									<AdRewardEstimator />
								</span>
							)}
							{
								!!profile?.organizationRole?.canSeeOrganizationBalance
									&& !profile?.organizationOwner && (
									<p className="text-secondary mb-0">
										{t(
											'HeaderUserbox.Index.organizationBalance',
											{
												pi: balance || 0,
												chips: chipBalance || 0,
												currencyLabel,
												systemCurrencyLabel,
											},
										)}
									</p>
								)
							}
						</div>
					</div>
				</DropdownToggle>
				<DropdownMenu end className="dropdown-menu-xl overflow-hidden shadow-none border border-secondary py-0 bg-transparent">
					<ListGroup flush className="text-left bg-transparent">
						<ListGroupItem className="rounded-top bg-light border-white-light">
							<Nav pills className="nav-neutral-secondary flex-column">
								{!profile?.linkedOrganization && organizations?.length > 1 ? (
									<NavItem className="border-bottom border-white-light">
										<NavLink onClick={handleOpenSwitchModal}>
											<span className="btn-wrapper--icon mr-1">
												<FaArrowCircleLeft />
											</span>
											{t('HeaderUserbox.Index.switchOrganization')}
										</NavLink>
									</NavItem>
								) : null}
								<NavItem className="border-bottom border-white-light white">
									<NavLink color="white" onClick={() => { toggleMenu(); setActiveTab('Messages'); }}>
										<div className="position-relative">
											<span className="btn-wrapper--icon mr-1">
												{totalUnreadCount > 0 && (
													<Badge
														color="danger"
														className="UserMenuDrawer_badge DropdownMenuBadge badge-circle shadow-none badge-position badge-position--top-left-fa"
													>
														<span className="text-xs" translate="no">
															{totalUnreadCount > 9 ? '9+' : totalUnreadCount}
														</span>
													</Badge>
												)}
												<MessagesIcon />
											</span>
											<span>{t('HeaderUserbox.Index.messagesAndNotifications')}</span>
										</div>
									</NavLink>
								</NavItem>
								{isBeeyou ? (
									<NavItem className="border-bottom border-white-light">
										<NavLink onClick={() => { toggleMenu(); setActiveTab('Friends'); }}>
											<div className="position-relative">
												<span className="btn-wrapper--icon mr-1">
													{pendingFriendRequests.length > 0 && (
														<Badge
															color="danger"
															className="UserMenuDrawer_badge badge-circle shadow-none badge-position badge-position--top-left-fa"
														>
															<span className="text-xs">{totalUnreadCount}</span>
														</Badge>
													)}
													<FriendsIcon />
												</span>
												{pendingFriendRequests.length > 0 ? (
													<span>
														<span className="friend">{t('HeaderUserbox.Index.friends')}</span> | {pendingFriendRequests.length} {t('HeaderUserbox.Index.friendsRequests')}
													</span>
												)
													: <span className="friend">{t('HeaderUserbox.Index.friends')}</span>}
											</div>
										</NavLink>
									</NavItem>
								) : null}
								{isBeeyou ? (
									<NavItem className="border-bottom border-white-light">
										<NavLink onClick={() => { toggleMenu(); setActiveTab('FollowingUsers'); }}>
											<span className="btn-wrapper--icon mr-1">
												<FaHeart />
											</span>
											<span className="following me-1">{t('HeaderUserbox.Index.following')}</span>|<span className="follower ms-1">{t('HeaderUserbox.Index.followers')}</span>
										</NavLink>
									</NavItem>
								) : null}
								<NavItem className="border-bottom border-white-light">
									<NavLink href={getChannelLink(profile.hashtag)}>
										<span className="btn-wrapper--icon mr-1">
											<MyChannelIcon className="icon" />
										</span>
										{t('HeaderUserbox.Index.channel')}
									</NavLink>
								</NavItem>
								<NavItem className="border-bottom border-white-light">
									<NavLink onClick={openUserSettings(() => openModal(UserSettingsModal.ACCOUNT))}>
										<span className="btn-wrapper--icon mr-1">
											<AccountIcon />
										</span>
										{t('HeaderUserbox.Index.account')}
									</NavLink>
								</NavItem>
								{defaultOrganization?._id !== profile?.organization?._id
									&& profile?.organizationRole?.canManageOrganization && (
									<NavItem className="border-bottom border-white-light">
										<NavLink onClick={openOrganizationSettings()}>
											<span className="btn-wrapper--icon mr-1">
												<AccountIcon />
											</span>
											{t('HeaderUserbox.Index.myOrganization')}
										</NavLink>
									</NavItem>
								)}
								{showMembership && (
									<NavItem className="border-bottom border-white-light">
										<NavLink
											onClick={openUserSettings(() => openModal(UserSettingsModal.MEMBERSHIP))}
										>
											<span className="btn-wrapper--icon mr-1">
												<FaTag />
											</span>
											{t('HeaderUserbox.Index.membership')}
										</NavLink>
									</NavItem>
								)}
								<NavItem className="border-bottom border-white-light">
									<NavLink
										onClick={
											openUserSettings(() => openModal(
												UserSettingsModal.LIBRARY, LibraryModalTab.VIDEOS,
											))
										}
									>
										<span className="btn-wrapper--icon mr-1">
											<LibraryIcon />
										</span>
										{t('HeaderUserbox.Index.library')}
									</NavLink>
								</NavItem>
								{isBeeyou && profile?.organization?._id === defaultOrganization?._id && (
									<NavItem className="border-bottom border-white-light">
										<NavLink
											onClick={openUserSettings(() => openAnalyticsAndAdModal(
												AnalyticsAndAdvertisingMenuItems.ANALYTICS,
											))}
										>
											<span className="btn-wrapper--icon mr-1">
												<FaChartBar className="icon" />
											</span>
											{t('SidebarMenu.Index.analytics')}
										</NavLink>
									</NavItem>
								)}
								{isBeeyou && profile?.organization?._id === defaultOrganization?._id && (
									<NavItem className="border-bottom border-white-light">
										<NavLink
											onClick={openUserSettings(() => openAnalyticsAndAdModal(
												AnalyticsAndAdvertisingMenuItems.ADVERTISING,
											))}
										>
											<span className="btn-wrapper--icon mr-1">
												<FaBuysellads className="icon" />
											</span>
											{t('SidebarMenu.Index.advertising')}
										</NavLink>
									</NavItem>
								)}
								{isBeeyou ? (
									<NavItem className="border-bottom border-white-light">
										<NavLink
											onClick={openUserSettings(() => openModal(UserSettingsModal.SURVEY_POLL))}
										>
											<span className="btn-wrapper--icon mr-1">
												<SurveysIcon />
											</span>
											{t('HeaderUserbox.Index.publicOpinionAnalysis')}
										</NavLink>
									</NavItem>
								) : null}
								<NavItem className="border-bottom border-white-light">
									<NavLink
										onClick={() => handleChangeCurrentScreenTheme(
											currentScreenTheme === ScreenThemeLayout.DARK
												? ScreenThemeLayout.LIGHT
												: ScreenThemeLayout.DARK,
										)}
									>
										<span className="btn-wrapper--icon mr-1">
											{currentScreenTheme === ScreenThemeLayout.DARK
												? <DarkThemeToggle />
												: <LightThemeToggle />}
										</span>
										{t('HeaderUserbox.Index.toggleTheme')} {currentScreenTheme === ScreenThemeLayout.DARK ? t('HeaderUserbox.Index.lightTheme') : t('HeaderUserbox.Index.darkTheme')}
									</NavLink>

								</NavItem>
							</Nav>
							<Nav pills className="nav-neutral-secondary flex-column">
								<NavItem>
									<NavLink
										active={false}
										onClick={handleSingOffWithConfirmation}
									>
										<span className="btn-wrapper--icon mr-1">
											{!isOrgPublicChannel ? <SignOffIcon /> : <FaArrowCircleLeft />}
										</span>
										{t(!isOrgPublicChannel ? 'HeaderUserbox.Index.logout' : 'HeaderUserbox.Index.switchToOrganization')}
									</NavLink>
								</NavItem>
							</Nav>
						</ListGroupItem>
					</ListGroup>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default HeaderUserbox;
