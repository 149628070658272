/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Button, ButtonDropdown, ButtonGroup, DropdownMenu, DropdownToggle,
	Nav, NavItem, NavLink,
} from 'reactstrap';
import { FaPowerOff } from 'react-icons/fa';
import { BsBroadcast } from 'react-icons/bs';

import { CompleteProfileModal } from '../Profile/CompleteModal';
import { useProfile } from '../Profile/ProfileContext';
import { StudioModal } from './Context';
import { useStudio } from './Provider';
import { StudioScheduleModal, useStudioSchedule } from '../StudioSchedule/Context';
import { useCurrentStudio } from './useCurrentStudio';
import { StudioState } from '../StudioSchedule/helper';
import { StudioGoLiveStopModal } from './GoLive/StopModal';
import { StudioGoLiveModal } from './GoLive/Modal';
import { useStudioStatus } from './Status/Context';
import { useScreenMode } from './ScreenMode/Provider';

import './Button.scss';

export const StudioButtonActive = () => {
	const { t } = useTranslation();
	const { setModalOpen } = useStudio();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isGoLiveModalOpen, setIsGoLiveModalOpen] = useState(false);
	const [isStopLiveModalOpen, setIsStopLiveModalOpen] = useState(false);
	const { openModal } = useStudioSchedule();

	const {
		isLiveAlmostTerminated: isLiveStopped,
		isLiveRecordingStarted,
		isLiveActive,
		isLiveAboutToStart,
		isLivePlanned,
		isStudioPublic,
		isStudioRunning,
	} = useStudioStatus();

	const showEndButton = !isStudioPublic
		|| isLiveStopped
		|| (isLiveAboutToStart && !isLiveRecordingStarted);
	const showGoLiveButton = isLivePlanned && !isLiveAboutToStart;
	const showStopLiveButton = isLiveActive || isLiveRecordingStarted;

	const handleClick = useCallback(() => {
		if (showEndButton) return setModalOpen(StudioModal.TERMINATE);
		if (showGoLiveButton) return setIsGoLiveModalOpen(true);
		if (showStopLiveButton) return setIsStopLiveModalOpen(true);
		if (isStudioRunning) return setModalOpen(StudioModal.TERMINATE);
		return null;
	}, [setModalOpen, showEndButton, showGoLiveButton, showStopLiveButton, isStudioRunning]);

	const goLiveModalInitialRender = useRef(false);

	useEffect(() => {
		if (showGoLiveButton && !goLiveModalInitialRender.current) {
			goLiveModalInitialRender.current = true;
			setIsGoLiveModalOpen(true);
		}
	}, [showGoLiveButton]);

	const isGoPublicLiveModalOpen = isLivePlanned && !isLiveAboutToStart && isGoLiveModalOpen;

	const isButtonDisabled = !isStudioRunning || (isLiveAboutToStart && !isLiveRecordingStarted);

	return (
		<>
			<ButtonGroup>
				<Button
					color="danger"
					className="flex-shrink-0 font-weight-bold px-2 px-xl-3"
					disabled={isButtonDisabled}
					onClick={handleClick}
					size="sm"
				>
					{showEndButton && (
						<FaPowerOff size={20} />
					)}
					{showGoLiveButton && (
						<>
							<BsBroadcast className="mr-sm-3" size={20} />
							<span className="d-none d-sm-block">{t('Studio.Button.goLive')}</span>
						</>
					)}
					{showStopLiveButton && <span className="d-none d-sm-block">{t('Studio.Button.stopLive')}</span>}
				</Button>
				<ButtonDropdown
					isOpen={dropdownOpen}
					toggle={() => setDropdownOpen(!dropdownOpen)}
				>
					<DropdownToggle
						caret
						className="px-1 StudioButtonDropdown"
						color="neutral-danger"
					/>
					<DropdownMenu end className="overflow-hidden p-2 bg-light border border-dark">
						<Nav pills className="nav-neutral-secondary flex-column font-weight-normal">
							<NavItem>
								<NavLink className="font-weight-normal" onClick={() => openModal(StudioScheduleModal.MANAGE, null, null, { defaultTab: StudioState.ONGOING })}>
									{t('Studio.Button.manage')}
								</NavLink>
							</NavItem>
							{!showEndButton && (
								<NavItem>
									<NavLink className="font-weight-normal" onClick={() => setModalOpen(StudioModal.TERMINATE)}>
										{t('Studio.Button.end')}
									</NavLink>
								</NavItem>
							)}
						</Nav>
					</DropdownMenu>
				</ButtonDropdown>
			</ButtonGroup>
			{isGoPublicLiveModalOpen && (
				<StudioGoLiveModal
					isOpen={isGoPublicLiveModalOpen}
					toggle={() => setIsGoLiveModalOpen(false)}
				/>
			)}
			{isStopLiveModalOpen && (
				<StudioGoLiveStopModal
					isOpen={isStopLiveModalOpen}
					toggle={() => setIsStopLiveModalOpen(false)}
				/>
			)}
		</>
	);
};

/**
 * @typedef {{
 * 	isFullscreen?: boolean,
 * }} StudioButtonLeaveProps
 */

export const StudioButtonLeave = (
/** @type {StudioButtonLeaveProps} */
	{ isFullscreen },
) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const {
		isCurrentStudioStarted,
	} = useCurrentStudio();

	const { toggleFullscreen } = useScreenMode();

	const handleLeaveStudio = useCallback(() => {
		if (isFullscreen) {
			toggleFullscreen();
		}
		navigate('/');
	}, [navigate, isFullscreen, toggleFullscreen]);

	return isCurrentStudioStarted
			&& (
				<Button
					color="danger"
					className="flex-shrink-0 font-weight-bold px-2 px-xl-3"
					onClick={handleLeaveStudio}
					size="sm"
				>
					<FaPowerOff size={20} />
				</Button>
			);
};

export const StudioButton = () => {
	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
	const { profile } = useProfile();

	return (
		<div className="d-flex align-items-center">
			{!profile?.completedRewardClaimed && (
				<CompleteProfileModal
					isOpen={showCompleteProfileModal}
					close={() => setShowCompleteProfileModal(false)}
				/>
			)}
		</div>
	);
};
