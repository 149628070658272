/* eslint-disable react/prop-types */
// @ts-check

import { ButtonGroup } from 'reactstrap';
import { useMatch } from 'react-router-dom';

import { useCurrentStudio } from './useCurrentStudio';
import { getLink, Path } from '../../RoutePath';
import { StudioButtonActive, StudioButtonLeave } from './Button';
import { StudioStatusProvider } from './Status/Provider';

/**
 * @typedef {{
 * 	className?: string,
 * 	controlroomActive?: boolean,
 * }} StudioStatusManagerProps
 */

export const StudioStatusManager = (
	/** @type {StudioStatusManagerProps} */
	{
		className = '',
		controlroomActive = false,
	},
) => {
	const {
		currentStudio,
	} = useCurrentStudio();

	const isHostRoute = useMatch({
		path: getLink(
			Path.STUDIO_HOST,
			{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
		),
		end: false,
	});
	const isOperatorRoute = useMatch({ path: getLink(
		Path.STUDIO_OPERATOR,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });
	const isParticipantRoute = useMatch({ path: getLink(
		Path.STUDIO_PARTICIPANT,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });

	const isStudioRoute = isHostRoute || isOperatorRoute || isParticipantRoute;

	return isStudioRoute && (
		<StudioStatusProvider studio={currentStudio}>
			<div className={`d-flex flex-column align-items-stretch ${className}`}>
				<div className="mt-1">
					{(isHostRoute || isOperatorRoute) && (
						controlroomActive ? (
							<ButtonGroup className="StudioButtonGroup">
								<StudioButtonActive />
							</ButtonGroup>
						) : (
							<StudioButtonLeave />
						)
					)}
					{isParticipantRoute && <StudioButtonLeave />}
				</div>
			</div>
		</StudioStatusProvider>
	);
};
